export default {
  shared: {
    cancel: "Cancel",
    save: "Save",
    back: "Back",
    settings: "Settings",
    write: "write",
    read: "read",
    confirm: "confirm",
    dismiss: "dismiss"
  },
  validations: {
    required: "Field is required",
    email: "Please insert a valid email",
    nineDigits: "Please input a 9-digit ID",
    passwordCriteria:
      "At least 8 digits and contain an uppercase, lowercase, and special character and a number",
    matching: "Fields do not match",
    minLength: "Min length",
    maxLength: "Max length",
    url: "Please insert a valid url"
  },
  messageTypes: {
    110: "joined the video conference.",
    120: "entered the workspace.",
    130: "left the video conference.",
    140: "left this workspace session.",
    200: "has been added as a member.",
    300: "has been removed from members.",
    400: "has abandoned the workspace.",
    100: "modified workspace info."
  },
  meta: {
    signIn: {
      title: "Sign in",
      description: "Login"
    },
    signUp: {
      title: "Sign Up",
      description: "Sign Up"
    },
    forgotPass: {
      title: "Forgot Password",
      description: "Forgot Password"
    },
    resetPass: {
      title: "Reset Password",
      description: "Reset Password"
    },
    guestJoin: {
      title: "Guest Join",
      description: "Join as guest"
    },
    home: {
      title: "Home",
      description: "Meeting Web App"
    },
    profile: {
      title: "Profile",
      description: "My profile"
    },
    rooms: {
      title: "My workspaces",
      description: "List of my workspaces"
    },
    roomDetail: {
      title: "Workspace",
      description: "Workspace"
    },
    roomNew: {
      title: "New workspace",
      description: "Create workspace"
    },
    conference: {
      title: "Conference",
      description: "Web conference"
    }
  },
  signin: {
    title: "Sign in",
    description: "Enter the credentials to sign in",
    email: "Email",
    password: "Password",
    forgot: "Forgot password?",
    cta: "Login",
    loginError: "Incorrect username or password",
    conferenceError: "Unauthorized access or expired session"
  },
  signUp: {
    title: "Sign Up",
    description: "Enter your email to sign up",
    disabled: "Sign Up disabled",
    email: "Email",
    cta: "Sign Up",
    success: "Signup success, please check your email",
    signUp: "Sign Up",
    error: "Signup errot, please retry",
    backToSignIn: "Return to sign in",
    addressNotAvailable:
      "This email address is not available. Choose a different address."
  },
  confirm: {
    title: "Confirm email",
    description: "Enter new password for",
    email: "Email",
    cta: "Sign Up",
    success: "Sign Up confirmed, please check your email",
    error: "Sign Up error",
    signUp: "Registrati",
    backToSignIn: "Back to Sign In",
    mailConfirmed: "Mail confirmed",
    mailConfirmError: "Error in mail confirmation",
    invitationTitle: "Confirm invitation",
    notConfirmedErr: "User already exist but email is not confirmed",
    resend: "Resend confirmation mail?",
    resendCTA: "Send",
    resendSuccess: "Confirmation sent, please check your email."
  },
  forgotPassword: {
    backToSignIn: "Return to sign in",
    forgotTitle: "Forgot password",
    forgotDescription:
      "Enter the email address associated with your account and we'll send you a link to reset your password",
    email: "Email",
    submit: "Submit",
    password: "Password",
    repeatPassword: "Repeat password",
    success:
      "An email with instructions to reset your password has been sent to your mailbox.",
    error: "Reset password error, please try again"
  },
  resetPassword: {
    title: "Reset password",
    description: "Enter new password",
    invalid: "The link is invalid or expired.",
    success: "Your password has been updated",
    error: "Reset password error, please retry",
    criteria:
      "Password should be alt least 8 digits and contain an uppercase, lowercase, and special character and a number",
    malformedUrl: "Wrong reset url, please retry",
    submit: "Submit"
  },
  join: {
    title: "Join as guest",
    description: "Enter the credentials of an existing workspace as a guest",
    meetingidLabel: "Workspace ID",
    pinLabel: "Access code",
    nickLabel: "Nickname",
    cta: "Join workspace",
    gotoHome: "Go to my account",
    gotoSignIn: "Back to sign in"
  },
  home: {
    logout: "Logout",
    myRooms: "My workspaces",
    rooms: "workspaces"
  },
  profile: {
    title: "My profile",
    update: "Update",
    name: "First Name",
    lastName: "Last Name",
    city: "City",
    updated: "Profile updated",
    updateError: "Update profile error, please retry later",
    uploadAvatarError: "Upload avatar error, please retry later"
  },
  mfa: {
    title: "Multi Factor Authentication",
    enable: "Enable",
    disable: "Disable",
    enableEmail: "Enable email Multi factor",
    insertCode: "Insert verification code",
    sendCodeEmail: "Require code",
    verifyCode: "Verify",
    verifyError: "Verification code error",
    activationSuccess: "Multifactor authentication active",
    activationError: "Error during multifactor activation",
    confirmActivationTitle: "Activation confirm",
    confirmEmailDescription:
      "Do you want to enable email Multifactor Authentication?",
    confirmAuthenticatorDescription:
      "Do you want to enable Multifactor Authentication?",
    codeSent: "Code sent, please check your mail",
    signInTitle: "Multifactor Access",
    signInDescription: "Complete Sign In with a multifactor provider",
    choseProvider: "Choose an authentication provider",
    getCodesCta: "See the codes",
    backupCodes: "Backup codes",
    backupCodesReset: "Reset backup codes",
    backupCodesDescription:
      "This list of one-time-use codes will help you reset your MFA. If you’re locked out of your account because your phone is lost, damaged, or replaced, or you cant access your email, you can use a backup code to reset your MFA and get back into your account. Copy this codes and keep them in a safe place.",
    qrTitle: "Scan the QR Code below with you preferred Authenticator app",
    qrDescription:
      "Any TOTP (Time-Based One Time Password) app supported. Google Authenticator recommended.",
    qrTip: "If you can't scan the QR Code, please use this manual code:",
    qrTitle2:
      "Insert the code generated by the app to enable multi-factor authentication",
    signInGoogle: "Sign in with Google",
    signInMicrosoft: "Sign in with Microsoft"
  },
  rooms: {
    back: "Back to home",
    newRoom: "New workspace",
    searchUser: "Invite members by name",
    autocompleteUser: "Type user name or lastname",
    roomSettingsFor: "Settings for",
    leaveRoom: "Leave workspace",
    roomName: "Workspace name",
    roomDescription: "Description",
    name: "Workspace name",
    manager: "Workspace manager",
    create: "Create workspace",
    backToRooms: "Back to my workspaces",
    enter: "Enter workspace",
    chat: "Chat",
    log: "Log",
    members: "Members",
    chatLabel: "Type something...",
    share: "Share workspace",
    shareDescription: "Click to copy the join information to the clipboard",
    copy: "Copy to Clipboard",
    copySuccess: "Join Link copied to the clipboard",
    invitation: {
      description: "You have been invited to a web meeting workspace:",
      app: "Join via app:",
      browser: "Join via browser:",
      download: "Download the app:"
    },
    leaving: "Are you sure?",
    confirmLeaving: "You are leaving the workspace:",
    updated: "Workspace updated",
    updateError: "Update workspace error, please retry later",
    uploadAvatarError: "Upload avatar error, please retry later",
    inviteByMail: "Invite by email",
    invitationSent: "Invitation Sent",
    sendToPod: "Send to room",
    sendToPodDescription:
      "Insert the room code to send the workspace to the conference room",
    roomCode: "Insert room code",
    backToShare: "Back to access info",
    exit: "Exit workspace",
    videoConference: "Video Conference",
    insertFile: "Insert file",
    largeFileTitle: "Warning: Large File",
    largeFileDescription:
      "You are uploading a large file, this could cause application slowdowns",
    insertText: "Insert text box",
    insertPostIt: "Insert post-it",
    insertWeb: "Insert web address",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    handlePages: "Handle pages"
  },
  conference: {
    qrTitle: "Connect your phone",
    qrDescription: "Scan with your phone camera to connect",
    leave: "Leave workspace",
    join: "Join meeting",
    waiting: "Waiting for a host...",
    id: "Workspace Id",
    pin: "Pin",
    addPage: "Add new page",
    addText: "Enter your text",
    loadingAssets: "Loading assets please wait",
    changeBackground: "Change Background",
    insertUrl: "Insert URL",
    addUrl: "Add",
    iframeDisclaimer:
      "Not all websites support the embedding of their content into other services",
    iframeOpen: "If you don't see the preview click here",
    confirmCancelElementTitle: "Delete board element",
    confirmCancelElementDescription:
      "This will permanently delete the selected board element",
    inviteUser: "Add member",
    inviteUserDescription:
      "Members have full access to the workspace. You can remove them at any time.",
    inviteGuest: "Invite guest",
    inviteGuestDescription:
      "Use access information to invite a guest user to workspace",
    inviteGuestDisabled: "Guest access disabled for this workspace",
    incomingTitle: "Incoming video conference",
    incomingDescription: "Do you want to open the videoconference window?"
  },
  solidViewer: {
    metallic: "Metallic Factor",
    roughness: "Roughness Factor",
    color: "Color",
    selectMaterial: "Select material"
  }
};
