export default {
  shared: {
    cancel: "Annulla",
    save: "Salva",
    back: "Indietro",
    settings: "Impostazioni",
    write: "scrittura",
    read: "lettura",
    confirm: "Conferma",
    dismiss: "Annulla"
  },
  validations: {
    required: "Il campo è richiesto",
    email: "Inserisci una mail valida",
    nineDigits: "Inserisici un codice di 9 numeri",
    passwordCriteria:
      "Almeno 8 caratteri una maiuscola, una minuscola, un carattere speciale e un numero",
    matching: "I campi non corrispondono",
    minLength: "Lunghezza minima",
    maxLength: "Lunghezza massima",
    url: "Inserisci un indirizzo valido"
  },
  messageTypes: {
    110: "è entrato nella videoconferenza",
    120: "è entrato nel workspace",
    130: "ha lasciato la videoconferenza",
    140: "ha lasciato il workspace",
    200: "è stato aggiunto come partecipante",
    300: "è stato rimosso come partecipante",
    400: "ha abbandonato il workspace",
    100: "ha cambiato le impostazioni dell workspace"
  },
  meta: {
    signIn: {
      title: "Accedi",
      description: "Accedi"
    },
    signUp: {
      title: "Registrati",
      description: "Registrati"
    },
    forgotPass: {
      title: "Password dimenticata",
      description: "Password dimenticata"
    },
    resetPass: {
      title: "Nuova Password",
      description: "Nuova Password"
    },
    guestJoin: {
      title: "Accesso ospite",
      description: "Accedi come ospite"
    },
    home: {
      title: "Home",
      description: "Web App"
    },
    profile: {
      title: "Profilo",
      description: "Il mio profilo"
    },
    rooms: {
      title: "I miei workspace",
      description: "Lista dei miei workspace"
    },
    roomDetail: {
      title: "Room",
      description: "Room"
    },
    roomNew: {
      title: "Nuova Room",
      description: "Crea una Room"
    },
    conference: {
      title: "Videoconferenza",
      description: "Web conference"
    }
  },
  signin: {
    title: "Accedi",
    description: "Inserisci le tue credenziali per accedere",
    email: "Email",
    password: "Password",
    forgot: "Password dimenticata?",
    cta: "Accedi",
    loginError: "Errore: username o password sbagliati",
    conferenceError: "Accesso non autorizzato o sessione scaduta"
  },
  signUp: {
    title: "Registrati",
    description: "Inserisci la tua mail per accedere",
    disabled: "Registrazione disabilitata",
    email: "Email",
    cta: "Registrati",
    success: "Registrazione confermata, controlla la tua mail",
    error: "Errore nella registrazione",
    signUp: "Registrati",
    backToSignIn: "Torna al Login",
    addressNotAvailable:
      "Questo indirizzo email non è disponibile. Scegli un indirizzo diverso."
  },
  confirm: {
    title: "Conferma email",
    description: "Enter new password for",
    email: "Email",
    cta: "Registrati",
    success: "Registrazione confermata, controlla la tua mail",
    error: "Errore nella registrazione",
    signUp: "Registrati",
    backToSignIn: "Torna al Login",
    mailConfirmed: "Mail confermata",
    mailConfirmError: "Errore nella conferma mail",
    invitationTitle: "Conferma Invito",
    notConfirmedErr: "Utente esistente ma mail non confermata",
    resend: "Reinviare mail di conferma?",
    resendCTA: "Invia",
    resendSuccess: "Conferma re inviata, controla la tua mail."
  },
  forgotPassword: {
    backToSignIn: "Torna al login",
    forgotTitle: "Password dimenticata",
    forgotDescription:
      "Inserisci la mail con cui ti sei registrato per recuperare la password",
    email: "Email",
    submit: "Invia",
    password: "Password",
    repeatPassword: "Ripeti password",
    success: "Codici di recupero inviati, controlla la tua mail",
    error: "Errore nel recupero password, riprova più tardi"
  },
  resetPassword: {
    title: "Reset password",
    description: "Inserisci la nuova password",
    invalid: "Il link di recupero è errato o scaduto.",
    success: "La tua password è stata aggiornata",
    error: "Errore nel reset password, per favore riprova",
    criteria:
      "Richiesti 8 caratteri una lettera maiuscola, minuscola, un numero e un carattere speciale",
    malformedUrl: "Errore nel link di recupero, per favore riprovare",
    submit: "Invia"
  },
  join: {
    title: "Accedi come ospite",
    description:
      "Inserisci le credenziali di un workspace per entrare come ospite",
    meetingidLabel: "Workspace ID",
    pinLabel: "Pin",
    nickLabel: "Nickname",
    cta: "Accedi",
    gotoHome: "Torna alla home",
    gotoSignIn: "Torna al Login"
  },
  home: {
    logout: "Esci",
    myRooms: "I miei workspace",
    rooms: "workspace"
  },
  profile: {
    title: "Il mio profilo",
    update: "Aggiorna",
    name: "Nome",
    lastName: "Cognome",
    city: "Città",
    updated: "Profilo aggiornato",
    updateError: "Errore nell'aggiornamento del profilo, riprova più tardi",
    uploadAvatarError: "Errore nel caricamento avatar, riprova più tardi"
  },
  mfa: {
    title: "Autenticazione Multi Factor",
    enable: "Abilita",
    disable: "Disablita",
    enableEmail: "Abilita Multi factor via mail",
    insertCode: "Inserisci codice di verifica",
    sendCodeEmail: "Richiedi codice",
    verifyCode: "Verifica",
    verifyError: "Errore di validazione del codice di verifica",
    activationSuccess: "Autenticazione multifactor attivata",
    activationError: "Errore nell'attivazione autenticazione multifactor",
    confirmActivationTitle: "Conferma l'attivazione",
    confirmEmailDescription:
      "Vuoi abilitare l'autenticazione multifactor via mail?",
    confirmAuthenticatorDescription:
      "Vuoi abilitare l'autenticazione multifactor con Authenticator?",
    codeSent: "Codice inviato, controlla la tua mail",
    signInTitle: "Accesso Multifactor",
    signInDescription: "Completa l'accesso con un provider multifactor",
    choseProvider: "Scegli un provider di autenticazione",
    getCodesCta: "Vedi codici",
    backupCodes: "Codici backup",
    backupCodesReset: "Ripristina i codici di backup",
    backupCodesDescription:
      "Questo elenco di codici monouso ti aiuterà a reimpostare l'autenticazione a più fattori. Se sei bloccato fuori dal tuo account perché non riesci ad accedere alla tua e-mail o al tuo telefono, puoi utilizzare un codice di backup per reimpostare l'autenticazione e tornare al tuo account. Copia questi codici e conservali in un luogo sicuro.",
    qrTitle:
      "Scansiona il codice QR qui sotto con la tua app Authenticator preferita",
    qrDescription:
      "È supportata qualsiasi app TOTP (Time-Based One Time Password). Consigliato Google Authenticator.",
    qrTip:
      "Se non riesci a scansionare il codice QR, utilizza questo codice manuale:",
    qrTitle2:
      "Inserisci il codice generato dall'app per abilitare l'autenticazione Muiltifactor",
    signInGoogle: "Accedi con Google",
    signInMicrosoft: "Accedi con Microsoft"
  },
  rooms: {
    back: "Torna alla home",
    newRoom: "Crea un workspace",
    searchUser: "Invita partecipanti per nome",
    autocompleteUser: "Inserisci nome o cognome",
    roomSettingsFor: "Impostazioni per",
    leaveRoom: "Lascia il workspace",
    roomName: "Nome",
    roomDescription: "Descrizione",
    name: "Nome",
    manager: "Amministratore",
    create: "Crea il workspace",
    backToRooms: "Torna ai miei workspace",
    enter: "Entra",
    chat: "Chat",
    log: "Log",
    members: "Partecipanti",
    chatLabel: "Scrivi un messagio...",
    share: "Condividi workspace",
    shareDescription: "Clicca per copiare le informazioni di accesso",
    copy: "Copia le informazioni",
    copySuccess: "Informazioni di invito copiate",
    invitation: {
      description: "Sei stato invitato nella meeting room:",
      app: "Entra con l'app:",
      browser: "Entra con il browser:",
      download: "Scarica l'app:"
    },
    leaving: "Sei sicuro?",
    confirmLeaving: "Stai lasciando il workspace:",
    updated: "Workspace aggiornato",
    updateError: "Errore nell'aggiornamento workspace, riprova più tardi",
    uploadAvatarError: "Errore nel caricamento avatar, riprova più tardi",
    inviteByMail: "Invita via email",
    invitationSent: "Invito inviato",
    sendToPod: "Invia a stanza",
    sendToPodDescription:
      "Inserisci il pin di condivisione per inviare il workspace alla conference room",
    roomCode: "Inserisci codice stanza",
    backToShare: "Torna alle informazioni di accesso",
    exit: "Esci dal workspace",
    videoConference: "Video Conferenza",
    insertFile: "Inserisci file",
    largeFileTitle: "Attenzione: File di grandi dimensioni",
    largeFileDescription:
      "Stai caricando un file di grandi dimensioni, potrebbero verificarsi rallentamenti",
    insertText: "Inserisci box di testo",
    insertPostIt: "Inserisci post-it",
    insertWeb: "Inserisci indirizzo web",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    handlePages: "Gestisci pagine"
  },
  conference: {
    qrTitle: "Connetti il tuo telefono",
    qrDescription:
      "Scansiona il codice la fotocamera del tuo telefono per connetterti",
    leave: "Esci",
    join: "Accedi alla videochat",
    waiting: "Attendi l'amministratore...",
    id: "Workspace Id",
    pin: "Pin",
    addPage: "Aggiungi pagina",
    addText: "Inserisci testo",
    loadingAssets: "Caricamento file in corso",
    changeBackground: "Cambia Sfondo",
    insertUrl: "Inserisci Indirizzo web / video",
    addUrl: "Aggiungi",
    iframeDisclaimer:
      "Non tutti i siti web supportano l'incorporamento del loro contenuto in altri servizi",
    iframeOpen: "Se non vedi l'anteprima clicca qui",
    confirmCancelElementTitle: "Cancella elemento",
    confirmCancelElementDescription:
      "L'elemento selezionato verrà cancellato in maniera permanente",
    inviteUser: "Aggiungi utenti",
    inviteUserDescription:
      "Invita un utente inserendo il suo nome o inserisci la mail per invitarlo ad accedere",
    inviteGuest: "Invita ospiti",
    inviteGuestDescription:
      "Usa le informazioni d'accesso per accedere come ospite",
    inviteGuestDisabled: "Accesso ospite disabilitato per questo workspace",
    incomingTitle: "Una videoconferenza è stata avviata",
    incomingDescription: "Vuoi aprire la finestra di videoconferenza?"
  },
  solidViewer: {
    metallic: "Lucentezza",
    roughness: "Ruvidità",
    color: "Colore",
    selectMaterial: "Scegli materiale"
  }
};
