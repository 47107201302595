import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import store, { RootState } from "@/store";
import Konva from "konva";
import { wbCanvasService } from "@/services/wb/wbCanvasService";
import { Stroke } from "@/models/wb/stroke.interface";
import {
  CanvasFile,
  fileManipulationSerializer
} from "@/models/wb/canvasFile.interface";
import { Page, SocketPageElement } from "@/models/wb/page.interface";
import * as conferenceService from "@/services/conferenceSocketService";
import { v4 as uuidv4 } from "uuid";
import lodash, { clone } from "lodash";
import Vue from "vue";
import * as wbFileService from "@/services/wb/wbFileService";
import * as wbConversionService from "@/services/wb/wbConversionService";
import loggerService from "@/services/loggerService";
import { Size } from "@/models/wb/size.interface";
import { GetPdfElementDataUrl } from "@/services/wb/elements/wbPdfElements.service";
import { BoardElement } from "@/models/wb/boardElement.interface";
import { calcPreviewSize } from "@/services/wb/elements/wbTexElemets.service";
import { getUpdatedFile } from "@/services/wb/wbDrawService";
import { updatemageStrokes } from "@/services/wb/wbFileService";
import {
  MaterialMetadata,
  RenderedMaterial
} from "@/models/wb/solidMaterial.interface";
import { ModelViewerElement } from "@google/model-viewer";
import { takeSnapShot } from "@/services/wb/elements/wbGlbElement.service";

export interface FilesState {
  imageFocusStage: Konva.Stage | undefined;
  imageFocusLayer: Konva.Layer | undefined;
  imagesLayer: Konva.Layer | undefined;
  images: BoardElement[];
  currentImage?: BoardElement;
  selectedImage?: BoardElement;
  imagesTransformer: Konva.Transformer | undefined;
  remoteFile2Load: CanvasFile | undefined;
  focusOnfile: boolean;
  selectedResourceUrl: string | undefined;
  selectedModelViewer: ModelViewerElement | undefined;
  isFullscreenResourceLoading: boolean;
  isAssetsLoading: boolean;
  cacheFiles: {
    url: string;
    uuid: string;
  }[];
}

type FilesContext = ActionContext<FilesState, RootState>;

export const namespaced = true;

export const state = (): FilesState => ({
  imageFocusStage: undefined,
  imageFocusLayer: undefined,
  imagesLayer: undefined,
  images: [],
  currentImage: undefined,
  imagesTransformer: undefined,
  remoteFile2Load: undefined,
  focusOnfile: false,
  selectedImage: undefined,
  selectedResourceUrl: undefined,
  selectedModelViewer: undefined,
  isFullscreenResourceLoading: false,
  isAssetsLoading: false,
  cacheFiles: []
});

export const getters: GetterTree<FilesState, RootState> = {
  // cacheFile: state => state.cacheFiles,
  cacheFileByUuid: state => (
    id: string
  ): { uuid: string; url: string } | undefined => {
    return state.cacheFiles.find(element => {
      return element.uuid == id;
    });
  },
  imageByUuid: state => (elementKey: string): CanvasFile | undefined => {
    const result = state.images.find(element => {
      return element.file.uuid == elementKey;
    });
    return result?.file;
  },
  imageFocusStage: (state): Konva.Stage | undefined => {
    return state.imageFocusStage;
  },
  imagesLayer: (state): Konva.Layer | undefined => {
    return state.imagesLayer;
  },
  currentImage: (state): BoardElement | undefined => {
    return state.currentImage;
  },
  selectedImage: (state): BoardElement | undefined => {
    return state.selectedImage;
  },
  remoteFile2Load: (state): CanvasFile | undefined => {
    return state.remoteFile2Load;
  },
  focusOnfile: state => {
    return state.focusOnfile;
  },
  selectedResourceUrl: state => {
    return state.selectedResourceUrl;
  },
  selectedModelViewer: state => {
    return state.selectedModelViewer;
  },
  isFullscreenResourceLoading: (state): boolean => {
    return state.isFullscreenResourceLoading;
  },
  isAssetsLoading: (state): boolean => {
    return state.isAssetsLoading;
  }
};

export const mutations: MutationTree<FilesState> = {
  setDraggableImages(state: FilesState, draggalbe: boolean) {
    state.images = state.images.map(element => {
      element.image.draggable(draggalbe);
      return element;
    });
  },
  deleteCachedFile(state: FilesState, uuid: string) {
    state.cacheFiles = state.cacheFiles.filter(file => {
      return file.uuid != uuid;
    });
  },
  setFullscreenLoading(state: FilesState, loading: boolean) {
    state.isFullscreenResourceLoading = loading;
  },
  setAssetsLoading(state: FilesState, loading: boolean) {
    state.isAssetsLoading = loading;
  },
  setImagesLayer(state: FilesState, layer: Konva.Layer) {
    state.imagesLayer = layer;
  },
  setImagesTransformer(state: FilesState, tr: Konva.Transformer) {
    state.imagesTransformer = tr;
  },
  clearImagesLayer(state: FilesState) {
    state.imagesLayer?.destroyChildren();
    const imageTransformer = wbCanvasService.createImageTransformer();
    state.imagesLayer?.add(imageTransformer);
    state.imagesTransformer = imageTransformer;
    state.images = [];
  },
  updateFocusImage(state: FilesState, file: CanvasFile) {
    state.imageFocusLayer?.find("Line").map(line => line.destroy());
    if (file.pagesStrokes) {
      file.pagesStrokes[file.currentPage]?.map(stroke => {
        const line = wbConversionService.Stroke2Line(stroke);
        state.imageFocusLayer?.add(line);
      });
    }
  },
  selectImage(
    state: FilesState,
    data: { image: Konva.Image; transform: boolean }
  ) {
    const maxZindex = (data.image.getParent()?.children?.length || 1) - 1;
    data.image.zIndex(maxZindex); //move image on top
    let nodes = [data.image] as Konva.Shape[];
    for (const stageImg of state.images) {
      if (stageImg.image._id == data.image._id) {
        state.selectedImage = stageImg;
        stageImg.lines.map(line => line.zIndex(maxZindex));
        nodes = nodes.concat(stageImg.lines);
      }
    }
    if (data.transform) {
      state.imagesTransformer?.nodes(nodes);
      state.imagesTransformer?.zIndex(maxZindex);
    }
  },
  deselectImage(state: FilesState) {
    state.selectedImage = undefined;
    state.imagesTransformer?.nodes([]);
  },
  setCurrentImage(state: FilesState, selectedImage?: BoardElement) {
    state.currentImage = selectedImage;
  },
  setSelectedImage(state: FilesState, selectedImage?: BoardElement) {
    state.selectedImage = selectedImage;
  },
  setSelectedResourceUrl(state: FilesState, url: string | undefined) {
    state.selectedResourceUrl = url;
  },
  setSelectedModelViewer(
    state: FilesState,
    model: ModelViewerElement | undefined
  ) {
    state.selectedModelViewer = model;
  },
  addImage(state: FilesState, data: BoardElement) {
    state.images.push(data);
  },
  updateImage(state: FilesState, file: Partial<CanvasFile>): void {
    state.images = state.images.map(image => {
      if (image.file.uuid === file.uuid && file.manipulation) {
        image.file.manipulation = file.manipulation;
        image.image.offsetX(
          (file.manipulation.x / file.manipulation.scaleX) * -1
        );
        image.image.offsetY(
          (file.manipulation.y / file.manipulation.scaleY) * -1
        );
        image.image.scaleX(file.manipulation.scaleX);
        image.image.scaleY(file.manipulation.scaleY);
        image.image.x(0);
        image.image.y(0);
        image.image.rotation(file.manipulation.rotation);

        if (file.details) {
          image.file.details = file.details;
          if (image.file.details.background && image.file.type == "post-it") {
            image.image.fill(image.file.details.background);
          }
        }
        image = updatemageStrokes(image, file);
      }
      return image;
    });
  },

  updateCurrentImageStrokes(
    state: FilesState,
    data: { file: CanvasFile; image: BoardElement }
  ): void {
    if (data.image) {
      data.image.lines.map(line => {
        line.destroy();
      });
      const newLines = [] as Konva.Line[];
      if (
        data.file?.pagesStrokes &&
        data.file?.pagesStrokes[data.file.currentPage]
      ) {
        wbConversionService.FileStrokes2Lines(data.file).map(line => {
          newLines.push(line);
          state.imagesLayer?.add(line);
        });
      }
      data.image.lines = newLines;
      data.image.file = data.file;
    }
  },
  setRemoteFile2Load(state: FilesState, fileId: CanvasFile) {
    state.remoteFile2Load = fileId;
  },
  clearRemoteFile2Load(state: FilesState) {
    state.remoteFile2Load = undefined;
  },
  deleteFile(state: FilesState, data: { fileId: string; local: boolean }) {
    state.images = state.images.filter(img => {
      if (img.file.uuid !== data.fileId) return true;
      else {
        //clean transformer if image is selected
        const node = state.imagesTransformer?.getNodes()[0];
        if (node && node._id === img.image._id) {
          data.local
            ? (state.selectedImage = undefined)
            : (state.currentImage = undefined);
          state.imagesTransformer?.nodes([]);
        }
        //delete traits
        img.lines.map(line => line.destroy());
        //delete konva image
        img.image.destroy();
        return false;
      }
    });
  },
  setFocusOnfile(state: FilesState, mounted: boolean) {
    state.focusOnfile = mounted;
  },
  setFocusStage(state: FilesState, stage: Konva.Stage) {
    state.imageFocusStage = stage;
  },
  setFocusLayer(state: FilesState, layer: Konva.Layer) {
    state.imageFocusLayer = layer;
  },
  updatePdfPage(
    state: FilesState,
    payload: { fileId: string; newImage: HTMLImageElement; currentPage: number }
  ) {
    state.images = state.images.map(img => {
      if (img.file.uuid === payload.fileId) {
        img.image.image(payload.newImage);
        img.file.currentPage = payload.currentPage;
        return img;
      }
      return img;
    });
  },
  updateImageFile(state: FilesState, file: CanvasFile) {
    state.images = state.images.map(img => {
      if (img.file.uuid !== file.uuid) return img;
      img.file = file;
      return img;
    });
  },
  updateImageLines(state: FilesState, data: { fileUuid: string; lines: [] }) {
    state.images = state.images.map(img => {
      if (img.file.uuid !== data.fileUuid) return img;
      img.lines = data.lines;
      return img;
    });
  },
  addStroke(state: FilesState, data: { stroke: Stroke; line: Konva.Line }) {
    state.imageFocusLayer?.add(data.line);
    state.currentImage?.lines.push(data.line);
    if (state.currentImage?.file?.pagesStrokes) {
      const currentpage = state.currentImage?.file.currentPage || 1;
      state.currentImage?.file?.pagesStrokes[currentpage]?.push(data.stroke);
    }
  },
  addNewLine(state: FilesState, line: Konva.Line) {
    state.imageFocusLayer?.add(line);
  },
  endDrawLine(state: FilesState, stroke: Stroke) {
    state.currentImage?.lines.push(wbConversionService.Stroke2Line(stroke));
    if (state.currentImage?.file?.pagesStrokes) {
      const currentPage = state.currentImage?.file.currentPage || 1;
      if (state.currentImage?.file?.pagesStrokes[currentPage]) {
        state.currentImage?.file?.pagesStrokes[currentPage]?.push(stroke);
      } else {
        state.currentImage.file.pagesStrokes[currentPage] = [stroke];
      }
    }
  }
};

export const actions: ActionTree<FilesState, RootState> = {
  async updatePostItDetail(
    context: FilesContext,
    payload: { background: string }
  ) {
    const element = store.getters["wb/files/selectedImage"] as BoardElement;
    element.file.details = {
      background: payload.background,
      text: ""
    };
    context.commit("updateImage", element.file);

    const page = store.getters["canvas/page"] as Page;
    const msgData = fileManipulationSerializer(element, page);
    conferenceService.sendUpdateImage(
      store.getters["conference/socket"],
      page.uuid,
      element.file.uuid,
      msgData
    );
  },
  async addCachedFile(
    context: FilesContext,
    payload: { uuid: string; url: string }
  ) {
    //check if file already exist
    const foundUrl = context.getters["cacheFileByUuid"](payload.uuid);
    if (foundUrl) return;
    context.state.cacheFiles.push(payload);
  },
  async addImageFromModelViewer(context: FilesContext, imageUrl: string) {
    let fileName = context.getters["selectedImage"].file.name as string;
    const nameSuffix = Math.random()
      .toString()
      .replace("0.", "");
    fileName = fileName.replace(".glb", nameSuffix + ".png");

    await wbFileService.uploadFile(imageUrl, fileName);
  },
  async drawFiles(
    context: FilesContext,
    data: {
      page: Page;
      imagesLayer: Konva.Layer;
    }
  ) {
    data.page?.files.map(async (file: CanvasFile, index, array) => {
      context.commit("setAssetsLoading", true);
      const image = await wbConversionService.File2KonvaNode(file);
      //Add pdf pages quantity
      if (file.type == "pdf" || file.type == "document") {
        const fileId = file.type === "document" ? file.pdfFileId : file.fileId;
        file.filePagesQuantity = await wbFileService.getFileInfo(fileId);
      }
      const lines = [] as Konva.Line[];
      //DRAW FILES
      data.imagesLayer?.add(image);
      if (file.pagesStrokes && file.pagesStrokes[file.currentPage]) {
        //DRAW STROKES ON FILES
        wbConversionService.FileStrokes2Lines(file).map(line => {
          lines.push(line);
          data.imagesLayer?.add(line);
        });
      }
      context.commit(
        "wb/files/addImage",
        { image: image, file: file, lines: lines },
        { root: true }
      );
      if (index == array.length - 1) {
        context.commit("setAssetsLoading", false);
      }
    });
  },
  async loadRemoteFile(
    context: FilesContext,
    payload: { canvasFile: CanvasFile; fileId: string; pdfFileId?: string }
  ) {
    const image = await wbFileService.loadRemoteFile(
      payload.canvasFile,
      payload.fileId,
      payload.pdfFileId
    );
    context.commit("addImage", image);
  },
  async updateTexFileContent(
    context: FilesContext,
    data: { canvasFile: CanvasFile; fileId: string }
  ) {
    //TODO need a complete rewrite -updatedTextElement updateTexFileContent onUpdateTextFile
    const text = await wbFileService.fetchFile(data.fileId, true);
    const file = lodash.cloneDeep(data.canvasFile);
    file.fileId = data.fileId;
    file.details ? (file.details.text = text) : (file.details = { text: text });

    if (data.canvasFile.fileId) {
      // EXISTING FILE
      context.dispatch("onUpdateTextFile", {
        newFile: file,
        oldFile: data.canvasFile
      });
    } else {
      // NEW FILE
      const image = await wbFileService.loadRemoteFile(file, file.fileId);
      context.commit("addImage", image);
    }
  },
  deleteSelectedImage(context: FilesContext) {
    if (context.state.selectedImage && context.rootState.canvas.currentPage) {
      conferenceService.deleteElementFromPage(
        store.getters["conference/socket"],
        {
          pageId: context.rootState.canvas.currentPage.uuid,
          imageId: context.state.selectedImage.file.uuid
        }
      );
      context.commit("deleteCachedFile", context.state.selectedImage.file.uuid);
      context.commit("deleteFile", {
        fileId: context.state.selectedImage.file.uuid,
        local: true
      });
    }
  },
  async duplicateFile(context: FilesContext) {
    if (context.state.selectedImage) {
      const clone = lodash.cloneDeep(context.state.selectedImage);
      clone.file.manipulation.x = clone.file.manipulation.x + 10;
      clone.file.manipulation.y = clone.file.manipulation.y + 10;
      clone.file.uuid = uuidv4();
      clone.image = await wbConversionService.File2KonvaNode(
        clone.file,
        true,
        clone.image.getAttrs().image.src
      );
      context.state.imagesLayer?.add(clone.image);
      context.commit("addImage", clone);
      //Send SignalR message
      wbFileService.sendNewElementToSocket(clone);
    }
  },
  async unfocusImage(context: FilesContext) {
    if (context.state.focusOnfile) {
      context.state.imageFocusStage?.destroy();
      const oldFile = context.state.selectedImage?.file as CanvasFile;
      if (oldFile.type == "glb") {
        return context.dispatch(
          "updateGlbElement",
          context.state.selectedImage
        );
      }
      const newFile =
        oldFile.type == "image" ||
        oldFile.type == "post-it" ||
        oldFile.type == "pdf"
          ? await getUpdatedFile(oldFile)
          : oldFile;
      if (newFile) newFile.filePagesQuantity = oldFile.filePagesQuantity;
      context.commit("setSelectedResourceUrl", undefined);
      context.commit("updateCurrentImageStrokes", {
        file: newFile,
        image: context.state.selectedImage
      });
      context.commit("canvas/updateCurrentPageFile", newFile, { root: true });
      context.commit("deselectImage");

      context.dispatch("wb/tools/setTool", "drag", { root: true });
      context.commit("setFocusStage", undefined);
      context.commit("setFocusOnfile", false);

      if (newFile) {
        context.state.images = await Promise.all(
          context.state.images.map(async image => {
            if (image.file.uuid === newFile.uuid && newFile.manipulation) {
              image = updatemageStrokes(image, newFile);
            }
            return image;
          })
        );
      }
    }
  },
  async focusImage(context: FilesContext, sendMessage = true) {
    if (context.state.focusOnfile) {
      if (sendMessage)
        await wbFileService.sendFocusFile(
          context.state.selectedImage,
          context.rootState.canvas.currentPage as Page,
          false
        );
      await context.dispatch("unfocusImage");
    } else {
      context.commit("setFullscreenLoading", true);
      sendMessage
        ? context.commit("setCurrentImage", context.state.selectedImage)
        : context.commit("setSelectedImage", context.state.currentImage);
      if (
        context.state.selectedImage &&
        context.rootState.canvas.currentPage?.size &&
        context.rootState.canvas.stage
        // &&
        // context.state.selectedImage.file.type !== "url"
      ) {
        context.commit("setFocusOnfile", true);
        await Vue.nextTick();
        const image = context.state.selectedImage.image;
        const lines = context.state.selectedImage.lines;
        const mainStage = context.rootState.canvas.stage;

        if (sendMessage) {
          await wbFileService.sendFocusFile(
            context.state.selectedImage,
            context.rootState.canvas.currentPage as Page,
            true
          );
        }

        //create another stage for focused img pdf documents and postit
        if (
          context.state.selectedImage.file.type === "image" ||
          context.state.selectedImage.file.type === "pdf" ||
          context.state.selectedImage.file.type === "document" ||
          context.state.selectedImage.file.type === "post-it"
        ) {
          store.commit("videoConference/setPinned", false);
          const focusStage = wbCanvasService.CreateStage(
            "#focusImageContainer",
            mainStage.width(),
            mainStage.height(),
            image.size().width,
            image.size().height,
            true,
            false
          );
          focusStage.draggable(false);

          const focusLayer = wbCanvasService.CreateLayer();

          const imageCopy = image.clone();
          imageCopy.draggable(false);
          imageCopy.rotation(0);
          imageCopy.x(0);
          imageCopy.y(0);
          imageCopy.offsetX(0);
          imageCopy.offsetY(0);
          imageCopy.scaleX(1);
          imageCopy.scaleY(1);

          focusLayer.add(imageCopy);

          context.dispatch("addNewLinesToFile", {
            lines: lines,
            stageLayer: focusLayer
          });

          focusStage.add(focusLayer);
          context.commit("setFocusLayer", focusLayer);
          context.commit("setFocusStage", focusStage);
        }
      }
      context.commit("setFullscreenLoading", false);
    }
  },
  async updateGlbElement(context: FilesContext, element: BoardElement) {
    //TODO need a better rewrite
    context.commit("setFullscreenLoading", true);
    const clonedElement = clone(element);
    let url;
    if (context.state.selectedModelViewer) {
      url = await takeSnapShot(context.state.selectedModelViewer);
    }

    context.commit("setFocusStage", undefined);
    context.commit("setFocusOnfile", false);
    context.dispatch("wb/tools/setTool", "drag", { root: true });

    clonedElement.image = await wbConversionService.File2KonvaNode(
      clonedElement.file,
      true,
      url
    );
    clonedElement.file.inFullscreen = false;
    context.commit("updateImage", clonedElement.file);

    context.commit("deleteFile", {
      fileId: element.file.uuid,
      local: true
    });

    context.commit("canvas/updateCurrentPageFile", clonedElement.file, {
      root: true
    });
    store.getters["wb/files/imagesLayer"].add(clonedElement.image);
    context.commit("addImage", clonedElement);
    context.commit("setFullscreenLoading", false);
  },
  async updatedTextElement(context: FilesContext, element: BoardElement) {
    //TODO need a complete rewrite -updatedTextElement updateTexFileContent onUpdateTextFile
    const clonedElement = clone(element);
    const text = element.file.details?.text || "";
    clonedElement.file.size = calcPreviewSize(text);
    const page = store.getters["canvas/page"];
    const socket = store.getters["conference/socket"];
    const fileObjectUrl = URL.createObjectURL(
      new Blob([text], { type: "text/plain" })
    );
    const fileId = await wbFileService.uploadFileRest(
      fileObjectUrl,
      clonedElement.file.name
    );
    const pageElement: SocketPageElement = {
      elementKey: clonedElement.file.uuid,
      fileId: fileId,
      pdfFileId: clonedElement.file.pdfFileId,
      pageKey: page.uuid
    };
    clonedElement.file.fileId = fileId;
    await conferenceService.setPageElementFiles(socket, pageElement);
    clonedElement.image = await wbConversionService.File2KonvaNode(
      clonedElement.file,
      true
    );
    await wbFileService.sendUpdatedFile(clonedElement, page);
    context.commit("updateImage", clonedElement.file);

    // UNFOCUS TEXT
    const oldFile = context.state.selectedImage?.file as CanvasFile;
    context.commit("deleteFile", {
      fileId: oldFile.uuid,
      local: true
    });

    context.commit("canvas/updateCurrentPageFile", clonedElement.file, {
      root: true
    });
    store.getters["wb/files/imagesLayer"].add(clonedElement.image);
    context.commit("addImage", clonedElement);
    context.dispatch("wb/tools/setTool", "drag", { root: true });
    context.commit("setFocusStage", undefined);
    context.commit("setFocusOnfile", false);
  },
  async changePdfPage(
    context: FilesContext,
    data: {
      file: BoardElement;
      offset: number;
    }
  ) {
    const newCanvasFile = lodash.cloneDeep(data.file.file);
    const currentPage = (data.file.file.currentPage as number) + data.offset;
    if (data.file.file.currentPage !== currentPage) {
      newCanvasFile.fileId = data.file.file.fileId || data.file.file.uuid;
      newCanvasFile.pdfFileId = data.file.file.pdfFileId;
      newCanvasFile.currentPage = currentPage;
      newCanvasFile.inFullscreen = context.state.focusOnfile;
      newCanvasFile.type = data.file.file.type;
      newCanvasFile.meetingId = data.file.file.meetingId;
      newCanvasFile.name = data.file.file.name;
      newCanvasFile.filePagesQuantity = data.file.file.filePagesQuantity;
      newCanvasFile.pagesStrokes = data.file.file.pagesStrokes;

      //Get pdf page strokes
      const lines = await wbFileService.getPdfPageStrokes(
        newCanvasFile.fileId as string,
        currentPage as number
      );
      const konvaLines = lines.map(line =>
        wbConversionService.Stroke2Line(line)
      );
      const url = await GetPdfElementDataUrl(newCanvasFile as CanvasFile);

      context.commit("updateImageFile", newCanvasFile);
      context.commit("updateImageLines", {
        fileId: newCanvasFile.uuid,
        lines: konvaLines
      });

      //Clear current image strokes
      context.commit("updateCurrentImageStrokes", {
        file: newCanvasFile,
        image: data.file
      });
      store.commit("canvas/updateCurrentPageFile", newCanvasFile);

      //Send signalR message
      // change pdf page by local action
      // context.commit("selectImage", data.file.image);
      wbFileService.sendUpdatedFile(
        data.file,
        context.rootState.canvas.currentPage as Page
      );

      const newPage = new Image();
      newPage.src = url;
      //Check if image in focus - set right page in full screen
      if (
        (data.file.file.type == "pdf" || data.file.file.type == "document") &&
        context.state.focusOnfile
      ) {
        context.dispatch("changeFocusPdfPage", {
          lines: konvaLines,
          newImage: newPage
        });
      }
      context.commit("updatePdfPage", {
        fileId: newCanvasFile.uuid,
        newImage: newPage,
        currentPage: newCanvasFile.currentPage
      });
    } else {
      context.commit("updateImage", data.file);
    }
  },
  async onReceiveUpdateFile(context: FilesContext, file: Partial<CanvasFile>) {
    const element = context.state.images.find(
      img => img.file.uuid === file.uuid
    );
    if (!element) {
      loggerService.warn(`Image ${file.uuid} not fount in state.images`);
      return;
    }
    element.file.size = file.size as Size;
    const currentPage = file.currentPage;

    //TEXT FILE
    if (element.file.type === "text") {
      return context.dispatch("onUpdateTextFile", {
        newFile: file,
        oldFile: element.file
      });
    }

    //BUG FULL SCREEN ANDROID
    // need to ignore some "echo" messages send by android when image is fullscreen
    if (
      context.state.focusOnfile &&
      file.inFullscreen &&
      element.file.currentPage === currentPage &&
      !file.details?.sphericalCoordinates &&
      !file.details?.materials
    ) {
      return;
    }
    //Focus use case
    if (context.state.focusOnfile !== file.inFullscreen) {
      element.file.inFullscreen = file.inFullscreen;
      return context.dispatch("toggleFullscreenElement", element);
    }

    //Change page use case
    if (element.file.currentPage !== currentPage) {
      const offset = currentPage ? currentPage - element.file.currentPage : 1;
      return context.dispatch("changePdfPage", {
        file: element,
        offset: offset
      });
    }

    //Rotate use case
    if (file.details?.sphericalCoordinates || file.details?.materials) {
      element.file.details = file.details;
      context.commit("updateImage", file);
      context.commit("setSelectedImage", element);
      context.commit("setCurrentImage", element);
      return;
    }

    context.commit("updateImage", file);
  },
  async toggleFullscreenElement(context: FilesContext, element: BoardElement) {
    if (element.file.inFullscreen) {
      context.commit("setCurrentImage", element);
      context.commit("setSelectedImage", element);
      context.dispatch("focusImage", true);
    } else {
      await context.dispatch("unfocusImage");
      context.commit("setCurrentImage", undefined);
      context.commit("setSelectedImage", undefined);
    }
  },
  async onUpdateTextFile(
    context: FilesContext,
    data: { newFile: Partial<CanvasFile>; oldFile: CanvasFile }
  ) {
    //TODO need a complete rewrite -updatedTextElement updateTexFileContent onUpdateTextFile
    //text file content change
    //UPDATE TEXT COLOR
    if (
      data.newFile.details &&
      data.oldFile.details &&
      (data.oldFile.details.background !== data.newFile.details.background ||
        data.oldFile.details.foreground !== data.newFile.details.foreground)
    ) {
      data.oldFile.details.background = data.newFile.details.background;
      data.oldFile.details.foreground = data.newFile.details.foreground;

      context.commit("canvas/updateCurrentPageFile", data.oldFile, {
        root: true
      });
      context.commit("deleteFile", {
        fileId: data.oldFile.uuid,
        local: true
      });
      await context.dispatch("loadRemoteFile", {
        canvasFile: data.oldFile,
        fileId: data.oldFile.fileId
      });
    }

    //UPDATE TEXT CONTENT
    if (
      data.newFile.details &&
      data.oldFile.details &&
      data.newFile.details.text &&
      data.oldFile.details.text !== data.newFile.details.text
    ) {
      data.oldFile.details.text = data.newFile.details.text;
      data.oldFile.size = calcPreviewSize(data.newFile.details.text);

      context.commit("canvas/updateCurrentPageFile", data.oldFile, {
        root: true
      });
      context.commit("deleteFile", {
        fileId: data.oldFile.uuid,
        local: true
      });
      await context.dispatch("loadRemoteFile", {
        canvasFile: data.oldFile,
        fileId: data.oldFile.fileId
      });
    }

    //UPDATE TEXT POSITION
    if (
      data.newFile.manipulation &&
      data.oldFile.manipulation &&
      data.oldFile.manipulation !== data.newFile.manipulation
    ) {
      data.oldFile.manipulation = data.newFile.manipulation;
    }

    context.commit("updateImage", data.oldFile);
  },
  changeFocusPdfPage(
    context: FilesContext,
    data: {
      lines: Konva.Line[];
      newImage: HTMLImageElement;
    }
  ) {
    const focusStageLayer = context.state.imageFocusStage?.find(
      "Layer"
    )[0] as Konva.Layer;
    //Destroy all old lines
    focusStageLayer.find("Line").map(line => line.destroy());
    //Change konva image url
    const image = focusStageLayer.find("Image")[0] as Konva.Image;
    image.image(data.newImage);
    //Add new lines
    context.dispatch("addNewLinesToFile", {
      lines: data.lines,
      stageLayer: focusStageLayer
    });
  },
  async updateGlbPosition(context: FilesContext, element: BoardElement) {
    const page = store.getters["canvas/page"];
    await wbFileService.sendUpdatedFile(element, page);
  },
  async updateGlbMaterial(
    context: FilesContext,
    data: {
      element: BoardElement;
      material: RenderedMaterial;
    }
  ) {
    //TODO: needs a full refactor
    let hit = false;
    if (data.element.file?.details?.materials) {
      data.element.file.inFullscreen = true;
      data.element.file.details.materials = data.element.file.details.materials.map(
        (m: MaterialMetadata) => {
          if (m.index == data.material.index) {
            hit = true;
            m.hexColor = data.material.hexColor;
            m.roughnessFactor = data.material.roughnessFactor;
            m.metallicFactor = data.material.metallicFactor;
          }
          return m;
        }
      );
    }
    if (!hit) {
      const mat = {
        index: data.material.index,
        metallicFactor: data.material.metallicFactor,
        roughnessFactor: data.material.roughnessFactor,
        hexColor: data.material.hexColor
      };
      if (data.element.file?.details?.materials) {
        data.element.file.details.materials.push(mat);
      } else {
        data.element.file.details.materials = [mat];
      }
    }
    const page = store.getters["canvas/page"];
    await wbFileService.sendUpdatedFile(data.element, page);
  },
  addNewLinesToFile(
    context: FilesContext,
    data: {
      lines: Konva.Line[];
      stageLayer: Konva.Layer;
    }
  ) {
    data.lines.map(line => {
      const lineCopy = line.clone();
      lineCopy.rotation(0);
      lineCopy.x(0);
      lineCopy.y(0);
      lineCopy.offsetX(0);
      lineCopy.offsetY(0);
      lineCopy.scaleX(1);
      lineCopy.scaleY(1);
      data.stageLayer.add(lineCopy);
    });
  },
  async updateImageLayer(context: FilesContext, page: Page) {
    await context.dispatch("drawFiles", {
      page: page,
      imagesLayer: context.state.imagesLayer
    });
  },
  selectImage(context: FilesContext, image: Konva.Image) {
    if (context.rootState.wb.tools.tool === "drag") {
      context.commit("selectImage", {
        image: image,
        transform: context.rootState.conference.isWriter
      });
    } else {
      image.stopDrag();
    }
  }
};
